import React, { createRef, useState, useEffect } from "react";
import Layout from "../../Layout";
import axios from "axios";
import swal from "sweetalert2";
import SEO from "../../seo";
import "sweetalert2/src/sweetalert2.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.scss";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

import "react-toastify/dist/ReactToastify.css";

const initialState = {
  firstName: "",
  lastName: "",
  currentName: "",
  email: "",
  contactNumber: "",
  batch: "",
  sectionStream: "",
  collegeUndergrad: "",
  courseUndergrad: "",
  yearUndergrad: "",
  collegePostgrad: "",
  coursePostgrad: "",
  yearPostgrad: "",
  currentProfession: "",
  industryField: "",
  currentEmployer: "",
  linkedInProfile: "",
  currentCityCountry: "",
  currentAddress: "",
  preferredCommMethod: "",
  chinmayaLinkedInGroup: "No",
  achievements: "",
  suggestions: "",
  schoolNewsletter: "No",
  participateInEvents: "No",
  volunteerForActivities: "No",
  mentorStudents: "No",
  areasOfExpertise: [],
  profilePicture: null,
  briefBio: "",
  favoriteMemories: "",
  schoolImpact: "",
  pincode: "",
};

export default function AlumniConnectPage() {
  const [values, setValues] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const photographRef = createRef(null);

  // Location state
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountryList(result);
    });
  }, []);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const expertiseOptions = [
    "Career Guidance",
    "Industry Insights",
    "Community Service",
    "Alumni Networking",
  ];

  const commMethodOptions = ["Phone", "Email", "WhatsApp"];

  const yesNoOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const validateForm = () => {
    if (!values.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      toast.error("Please enter a valid email address");
      return false;
    }

    if (values.contactNumber.length < 10) {
      toast.error("Please enter a valid contact number");
      return false;
    }
    if (!values.firstName) {
      toast.error("First name is required");
      return false;
    }

    if (!values.lastName) {
      toast.error("Last name is required");
      return false;
    }

    if (!values.currentProfession) {
      toast.error("Current profession is required");
      return false;
    }

    if (!values.industryField) {
      toast.error("Industry/Field is required");
      return false;
    }

    if (
      !selectedCountry ||
      !selectedState ||
      !selectedCity ||
      !values.pincode
    ) {
      toast.error("Complete address details are required");
      return false;
    }

    if (!captchaVerified) {
      toast.error("Please complete the CAPTCHA");
      return false;
    }

    return true;
  };

  const handleInputChange = (e) => {
    if (e.target.type !== "file") {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: e.target.files[0] });
    }

    if (e.target.name === "profilePicture" && e.target.files[0]) {
      photographRef.current.src = window.URL.createObjectURL(e.target.files[0]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    let params = new FormData();
    // Combine location data
    const locationString = `${selectedCity}, ${selectedState}, ${selectedCountry}${
      values.pincode ? ` - ${values.pincode}` : ""
    }`;

    // Update currentCityCountry with combined location data
    const updatedValues = {
      ...values,
      currentCityCountry: locationString,
    };

    Object.keys(updatedValues).forEach((key) => {
      params.append(key, updatedValues[key]);
    });

    try {
      const response = await axios.post(
        "https://chinmayavvdelhi.ac.in/api/public/index.php/alumni_connect",
        params,
        { headers: { "content-type": "multipart/form-data" } }
      );
      new swal({
        type: "success",
        title: "Thank you! Form submitted successfully!",
        timer: 3000,
      });
      setIsSubmitting(false);
      setValues(initialState);
      // Reset location states
      setCountryid(0);
      setStateid(0);
      setCityid(0);
      setSelectedCountry("");
      setSelectedState("");
      setSelectedCity("");
    } catch (e) {
      new swal({
        title: "Error",
        html: `<div style='text-align: center'>Internal Server Error</div>`,
        type: "error",
      });
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = (e, expertise) => {
    if (e.target.checked) {
      setValues({
        ...values,
        areasOfExpertise: [...values.areasOfExpertise, expertise],
      });
    } else {
      setValues({
        ...values,
        areasOfExpertise: values.areasOfExpertise.filter(
          (item) => item !== expertise
        ),
      });
    }
  };

  const handlePhoneChange = (phone) => {
    setValues({ ...values, contactNumber: phone });
  };

  return (
    <Layout>
      <SEO
        title="Alumni Connect | Chinmaya Vidyalaya"
        description="Join our alumni network by submitting your professional and personal details."
      />
      <div className="alumni-connect">
        <ToastContainer />
        <h1 className="heading">Alumni Connect</h1>
        <p>
          As a valued alumnus/alumna of Chinmaya Vidyalaya New Delhi, you are
          invited to join our growing network. Complete this form to reconnect,
          share your story and achievements, and explore opportunities to engage
          with fellow alumni and current students.
          <br />
          <br />
          Chinmay Vidyalaya New Delhi will take steps to protect the information
          you share with us.{" "}
        </p>
        <form onSubmit={handleFormSubmit} className="alumni-connect__form">
          <div className="row">
            {/* Section 1: Basic Information */}
            <h3>Section 1: Basic Information</h3>
            <div className="col col-12 col-lg-6">
              <label>First Name*</label>
              <input
                value={values.firstName}
                name="firstName"
                onChange={handleInputChange}
                placeholder="First Name (as registered during school days)"
                className="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Last Name*</label>
              <input
                value={values.lastName}
                name="lastName"
                onChange={handleInputChange}
                placeholder="Last Name (as registered during school days)"
                className="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Current Name (if different)</label>
              <input
                value={values.currentName}
                name="currentName"
                onChange={handleInputChange}
                placeholder="Current Name"
                className="alumni-connect__form__input"
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Email Address*</label>
              <input
                value={values.email}
                name="email"
                type="email"
                onChange={handleInputChange}
                placeholder="Email Address"
                className="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Contact Number*</label>
              {/*   <input
                value={values.contactNumber}
                name="contactNumber"
                onChange={handleInputChange}
                placeholder="Contact Number"
                className="alumni-connect__form__input"
                required
              />

               */}

              <PhoneInput
                country={"in"}
                value={values.contactNumber}
                onChange={handlePhoneChange}
                inputClass="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Batch/Class (Year of graduation from school)*</label>
              <input
                value={values.batch}
                name="batch"
                onChange={handleInputChange}
                placeholder="Batch (Year of graduation from school)"
                className="alumni-connect__form__input"
                maxLength={4}
                pattern="\d{4}"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Stream*</label>
              <input
                value={values.sectionStream}
                name="sectionStream"
                onChange={handleInputChange}
                placeholder="Science/Commerce/Humanities"
                className="alumni-connect__form__input"
                required
              />
            </div>

            <h3>Section 2: Education & Career</h3>
            <div className="col col-12 col-lg-6">
              <label>College (Undergraduate)</label>
              <input
                value={values.collegeUndergrad}
                name="collegeUndergrad"
                onChange={handleInputChange}
                placeholder="Undergraduate College"
                className="alumni-connect__form__input"
              />
            </div>

            <div className="col col-12 col-lg-6">
              <label>Year (undergraduate or vocational course)</label>
              <input
                value={values.yearUndergrad}
                name="yearUndergrad"
                onChange={handleInputChange}
                placeholder="Year of Graduation"
                className="alumni-connect__form__input"
                maxLength={4}
                pattern="\d{4}"
              />
            </div>
            <div className="col col-12 col-lg-6">
              {/* <label>Course (Undergraduate) </label> */}
              <label>
                Professional/ Vocational Courses (or any other courses)
              </label>
              <input
                value={values.courseUndergrad}
                name="courseUndergrad"
                onChange={handleInputChange}
                placeholder="Undergraduate Course"
                className="alumni-connect__form__input"
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>College (Postgraduate)</label>
              <input
                value={values.collegePostgrad}
                name="collegePostgrad"
                onChange={handleInputChange}
                placeholder="Postgraduate College"
                className="alumni-connect__form__input"
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Course (Postgraduate)</label>
              <input
                value={values.coursePostgrad}
                name="coursePostgrad"
                onChange={handleInputChange}
                placeholder="Postgraduate Course"
                className="alumni-connect__form__input"
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Year (Postgraduate)</label>
              <input
                value={values.yearPostgrad}
                name="yearPostgrad"
                onChange={handleInputChange}
                placeholder="Year of Postgrad"
                className="alumni-connect__form__input"
                maxLength={4}
                pattern="\d{4}"
              />
            </div>

            <div className="col col-12 col-lg-6">
              <label>Current Profession*</label>
              <input
                value={values.currentProfession}
                name="currentProfession"
                onChange={handleInputChange}
                placeholder="Current Profession"
                className="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Industry/Field*</label>
              <input
                value={values.industryField}
                name="industryField"
                onChange={handleInputChange}
                placeholder="Industry/Field"
                className="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Employer (Organisation Name)</label>
              <input
                value={values.currentEmployer}
                name="currentEmployer"
                onChange={handleInputChange}
                placeholder="Current Employer"
                className="alumni-connect__form__input"
              />
            </div>

            <h3>Section 3: Contact & Social Media</h3>
            <div className="col col-12 col-lg-6">
              <label>LinkedIn Profile</label>
              <input
                value={values.linkedInProfile}
                name="linkedInProfile"
                onChange={handleInputChange}
                placeholder="LinkedIn Profile (optional)"
                className="alumni-connect__form__input"
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Country*</label>
              <select
                onChange={(e) => {
                  const country = countryList.filter(
                    (country) => country.id == Number(e.target.value)
                  )[0];
                  setCountryid(country.id);
                  setSelectedCountry(country.name);
                  setCityList([]);
                  setStateList([]);
                  GetState(country.id).then((result) => {
                    setStateList(result);
                  });
                }}
                value={countryid}
                className="alumni-connect__form__select"
                required
              >
                <option style={{ display: "none" }} value="">
                  Select Country
                </option>
                {countryList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col col-12 col-lg-6">
              <label>State*</label>
              <select
                onChange={(e) => {
                  const state = stateList.filter(
                    (state) => state.id == Number(e.target.value)
                  )[0];

                  setStateid(state.id);
                  setSelectedState(state.name);
                  GetCity(countryid, state.id).then((result) => {
                    setCityList(result);
                  });
                }}
                value={stateid}
                className="alumni-connect__form__select"
                required
              >
                <option style={{ display: "none" }} value="">
                  Select State
                </option>
                {stateList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col col-12 col-lg-6">
              <label>City*</label>
              <select
                onChange={(e) => {
                  const city = cityList.filter(
                    (city) => city.id == Number(e.target.value)
                  )[0];

                  setCityid(city.id);
                  setSelectedCity(city.name);
                }}
                value={cityid}
                className="alumni-connect__form__select"
                required
              >
                <option style={{ display: "none" }} value="">
                  Select City
                </option>
                {cityList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col col-12 col-lg-6">
              <label>Pincode*</label>
              <input
                value={values.pincode}
                name="pincode"
                onChange={handleInputChange}
                placeholder="Enter Pincode"
                className="alumni-connect__form__input"
                required
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Current Address</label>
              <input
                value={values.currentAddress}
                name="currentAddress"
                onChange={handleInputChange}
                placeholder="Current Address"
                className="alumni-connect__form__input"
              />
            </div>
            <div className="col col-12 col-lg-6">
              <label>Preferred Method of Communication*</label>
              <select
                value={values.preferredCommMethod}
                name="preferredCommMethod"
                onChange={handleInputChange}
                className="alumni-connect__form__select"
                required
              >
                <option value="">--- Select Method ---</option>
                {commMethodOptions.map((method) => (
                  <option value={method}>{method}</option>
                ))}
              </select>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Have you joined the Chinmaya LinkedIn Alumni Group?</label>
              <select
                value={values.chinmayaLinkedInGroup}
                name="chinmayaLinkedInGroup"
                onChange={handleInputChange}
                className="alumni-connect__form__select"
              >
                {yesNoOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>

              {values.chinmayaLinkedInGroup == "No" && (
                <>
                  <a
                    target="__blank"
                    style={{ display: "block", marginTop: "10px" }}
                    href="https://bit.ly/4dFRqPI"
                  >
                    Join Alumni Group
                  </a>
                </>
              )}
            </div>

            {/* Section 4: Interests & Involvement */}
            <h3>Section 4: Interests & Involvement</h3>
            <div className="col col-12 col-lg-6">
              <label>Would you like to participate in alumni events?</label>
              <select
                value={values.participateInEvents}
                name="participateInEvents"
                onChange={handleInputChange}
                className="alumni-connect__form__select"
              >
                {yesNoOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Interested in volunteering for school activities?</label>
              <select
                value={values.volunteerForActivities}
                name="volunteerForActivities"
                onChange={handleInputChange}
                className="alumni-connect__form__select"
              >
                {yesNoOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Would you like to mentor current students?</label>
              <select
                value={values.mentorStudents}
                name="mentorStudents"
                onChange={handleInputChange}
                className="alumni-connect__form__select"
              >
                {yesNoOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Areas of Expertise</label>
              <div className="alumni-connect__form__checkbox-group">
                {expertiseOptions.map((expertise, index) => (
                  <div key={index} className="alumni-connect__form__checkbox">
                    <input
                      type="checkbox"
                      name="areasOfExpertise"
                      value={expertise}
                      checked={values.areasOfExpertise.includes(expertise)}
                      onChange={(e) => handleCheckboxChange(e, expertise)}
                    />
                    <label>{expertise}</label>
                  </div>
                ))}
              </div>
            </div>

            <h3>Section 5: Update & Feedback</h3>
            <div className="col col-12 col-lg-6">
              <label>Achievements & Awards</label>
              <textarea
                value={values.achievements}
                name="achievements"
                onChange={handleInputChange}
                placeholder="Share your notable achievements or awards"
                className="alumni-connect__form__textarea"
              ></textarea>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Suggestions</label>
              <textarea
                value={values.suggestions}
                name="suggestions"
                onChange={handleInputChange}
                placeholder="Any suggestions for the school or alumni community"
                className="alumni-connect__form__textarea"
              ></textarea>
            </div>
            <div className="col col-12 col-lg-6">
              <label>
                Would you like to receive school newsletters/updates?
              </label>
              <select
                value={values.schoolNewsletter}
                name="schoolNewsletter"
                onChange={handleInputChange}
                className="alumni-connect__form__select"
              >
                {yesNoOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>

            {/* Additional Fields */}
            <div className="col col-12 col-lg-6">
              <label>Upload Profile Picture*</label>
              <input
                name="profilePicture"
                type="file"
                onChange={handleInputChange}
                placeholder="Profile Picture"
                accept=".png,.jpeg,.jpg"
                className="alumni-connect__form__input"
                required
              />
              {
                <img
                  className="alumni-connect__form__photographpreview"
                  ref={photographRef}
                  style={{ display: values.profilePicture ? "block" : "none" }}
                  alt="Profile Preview"
                />
              }
            </div>
            <div className="col col-12 col-lg-6">
              <label>Brief Bio</label>
              <textarea
                value={values.briefBio}
                name="briefBio"
                onChange={handleInputChange}
                placeholder="Brief Bio"
                className="alumni-connect__form__textarea"
              ></textarea>
            </div>
            <div className="col col-12 col-lg-6">
              <label>Favourite School Memories (optional)</label>
              <textarea
                value={values.favoriteMemories}
                name="favoriteMemories"
                onChange={handleInputChange}
                placeholder="Favourite School Memories"
                className="alumni-connect__form__textarea"
              ></textarea>
            </div>
            <div className="col col-12 col-lg-6">
              <label>
                How did your school experience shape your life/career?
              </label>
              <textarea
                value={values.schoolImpact}
                name="schoolImpact"
                onChange={handleInputChange}
                placeholder="Describe how your school experience shaped your life or career"
                className="alumni-connect__form__textarea"
              ></textarea>
            </div>

            {/* Submit button */}
          </div>
          <div className="recaptcha-container">
            <ReCAPTCHA
              sitekey="6Lf6l3QqAAAAAJYLVFVz9w5bUeg8EFl4sEPJqsz8"
              onChange={handleCaptchaChange}
            />
          </div>
          <button
            disabled={isSubmitting}
            className="alumni-connect__form__button"
          >
            {!isSubmitting ? "Submit" : "Submitting..."}
          </button>
        </form>
      </div>
    </Layout>
  );
}
